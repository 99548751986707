*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}
